export const defaultSims = [
    {
        _id: {},
        Name: "m5out-actual\\m5out-1",
        l1d_size: "8192",
        l2_size: "131072",
        branch_pred_type: "LocalBP",
        num_rob_entries: "32",
        core_type: "BaseO3CPU",
        num_phys_int_regs: "128",
        fu_list_0_count: "1",
        fu_list_1_count: "1",
        L1doverallHits: "36682315",
        L1doverallMisses: "5107999",
        L1doverallAccesses: "41790314",
        L1doverallMissRate: "0.122229",
        L1doverallMshrHits: "242264",
        L1doverallMshrMisses: "4881027",
        L1doverallMshrMissRate: "0.116798",
        L1dreplacements: "4881026",
        L1ioverallHits: "33368591",
        L1ioverallAccesses: "33368591",
        L2overallHits: "1860099",
        L2overallMisses: "3020927",
        L2overallAccesses: "4881026",
        L2overallMissRate: "0.618912",
        L2overallMshrHits: "3124",
        L2overallMshrMisses: "3034607",
        L2overallMshrMissRate: "0.621715",
        L2replacements: "3035451",
        ctrl0readReqs: "1509364",
        ctrl0writeReqs: "118798",
        ctrl0totQLat: "29542643718",
        ctrl0totBusLat: "4998959616",
        ctrl0totMemAccLat: "55785681414",
        ctrl0avgQLat: "19691.32",
        ctrl0avgBusLat: "3332.00",
        ctrl0avgMemAccLat: "37183.32",
        ctrl0readRowHits: "442683",
        ctrl0writeRowHits: "102700",
        ctrl0readRowHitRate: "29.51",
        ctrl0writeRowHitRate: "88.81",
        ctrl0pageHitRate: "33.75",
        ctrl1readReqs: "1525243",
        ctrl1writeReqs: "118710",
        ctrl1totQLat: "29801184757",
        ctrl1totBusLat: "5051931752",
        ctrl1totMemAccLat: "56322310269",
        ctrl1avgQLat: "19655.36",
        ctrl1avgBusLat: "3332.00",
        ctrl1avgMemAccLat: "37147.36",
        ctrl1readRowHits: "451234",
        ctrl1writeRowHits: "102860",
        ctrl1readRowHitRate: "29.76",
        ctrl1writeRowHitRate: "88.96",
        ctrl1pageHitRate: "33.96",
        numCycles: "512161847",
        cpi: "5.121618",
        ipc: "0.195251",
        lookups_0: "53981123",
        squashes_0: "26965950",
        corrected_0: "3074067",
        committed_0: "27015174",
        mispredicted_0: "2690433",
        targetWrong_0: "3073539",
        condPredicted: "35617341",
        condPredictedTaken: "13629673",
        condIncorrect: "3074067",
        predTakenBTBMiss: "0",
        NotTakenMispredicted: "2122824",
        TakenMispredicted: "951243",
        BTBLookups: "53981123",
        BTBUpdates: "2122467",
        BTBHits: "36720784",
        BTBHitRatio: "0.680252",
        BTBMispredicted: "0",
        indirectLookups: "7605444",
        indirectHits: "6839635",
        indirectMisses: "765809",
        indirectMispredicted: "0",
        numInsts: "100000001",
        numOps: "175084279",
        branchMispredicts: "1983008",
        SINo_OpClass: "4848315      2.43%      2.43%",
        SIIntAlu: "146427735     73.34%     75.77%",
        SIIntMult: "185554      0.09%     75.86%",
        SIIntDiv: "521329      0.26%     76.12%",
        SIFloatAdd: "0      0.00%     76.12%",
        SIFloatCmp: "0      0.00%     76.12%",
        SIFloatCvt: "0      0.00%     76.12%",
        SIFloatMult: "0      0.00%     76.12%",
        SIFloatMultAcc: "0      0.00%     76.12%",
        SIFloatDiv: "0      0.00%     76.12%",
        SIFloatMisc: "0      0.00%     76.12%",
        SIFloatSqrt: "0      0.00%     76.12%",
        SISimdAdd: "0      0.00%     76.12%",
        SISimdAddAcc: "0      0.00%     76.12%",
        SISimdAlu: "0      0.00%     76.12%",
        SISimdCmp: "0      0.00%     76.12%",
        SISimdCvt: "0      0.00%     76.12%",
        SISimdMisc: "0      0.00%     76.12%",
        SISimdMult: "0      0.00%     76.12%",
        SISimdMultAcc: "0      0.00%     76.12%",
        SISimdShift: "0      0.00%     76.12%",
        SISimdShiftAcc: "0      0.00%     76.12%",
        SISimdDiv: "0      0.00%     76.12%",
        SISimdSqrt: "0      0.00%     76.12%",
        SISimdFloatAdd: "0      0.00%     76.12%",
        SISimdFloatAlu: "0      0.00%     76.12%",
        SISimdFloatCmp: "0      0.00%     76.12%",
        SISimdFloatCvt: "0      0.00%     76.12%",
        SISimdFloatDiv: "0      0.00%     76.12%",
        SISimdFloatMisc: "0      0.00%     76.12%",
        SISimdFloatMult: "0      0.00%     76.12%",
        SISimdFloatMultAcc: "0      0.00%     76.12%",
        SISimdFloatMatMultAcc: "0      0.00%     76.12%",
        SISimdFloatSqrt: "0      0.00%     76.12%",
        SISimdReduceAdd: "0      0.00%     76.12%",
        SISimdReduceAlu: "0      0.00%     76.12%",
        SISimdReduceCmp: "0      0.00%     76.12%",
        SISimdFloatReduceAdd: "0      0.00%     76.12%",
        SISimdFloatReduceCmp: "0      0.00%     76.12%",
        SISimdAes: "0      0.00%     76.12%",
        SISimdAesMix: "0      0.00%     76.12%",
        SISimdSha1Hash: "0      0.00%     76.12%",
        SISimdSha1Hash2: "0      0.00%     76.12%",
        SISimdSha256Hash: "0      0.00%     76.12%",
        SISimdSha256Hash2: "0      0.00%     76.12%",
        SISimdShaSigma2: "0      0.00%     76.12%",
        SISimdShaSigma3: "0      0.00%     76.12%",
        SISimdPredAlu: "0      0.00%     76.12%",
        SIMatrix: "0      0.00%     76.12%",
        SIMatrixMov: "0      0.00%     76.12%",
        SIMatrixOP: "0      0.00%     76.12%",
        SIMemRead: "35311909     17.69%     93.81%",
        SIMemWrite: "12365616      6.19%    100.00%",
        SIFloatMemRead: "0      0.00%    100.00%",
        SIFloatMemWrite: "0      0.00%    100.00%",
        SIIprAccess: "0      0.00%    100.00%",
        SIInstPrefetch: "0      0.00%    100.00%",
        SIVectorUnitStrideLoad: "0      0.00%    100.00%",
        SIVectorUnitStrideStore: "0      0.00%    100.00%",
        SIVectorUnitStrideMaskLoad: "0      0.00%    100.00%",
        SIVectorUnitStrideMaskStore: "0      0.00%    100.00%",
        SIVectorStridedLoad: "0      0.00%    100.00%",
        SIVectorStridedStore: "0      0.00%    100.00%",
        SIVectorIndexedLoad: "0      0.00%    100.00%",
        SIVectorIndexedStore: "0      0.00%    100.00%",
        SIVectorUnitStrideFaultOnlyFirstLoad: "0      0.00%    100.00%",
        SIVectorWholeRegisterLoad: "0      0.00%    100.00%",
        SIVectorWholeRegisterStore: "0      0.00%    100.00%",
        SIVectorIntegerArith: "0      0.00%    100.00%",
        SIVectorFloatArith: "0      0.00%    100.00%",
        SIVectorFloatConvert: "0      0.00%    100.00%",
        SIVectorIntegerReduce: "0      0.00%    100.00%",
        SIVectorFloatReduce: "0      0.00%    100.00%",
        SIVectorMisc: "0      0.00%    100.00%",
        SIVectorIntegerExtension: "0      0.00%    100.00%",
        SIVectorConfig: "0      0.00%    100.00%",
        SItotal: "199660458",
        CNo_OpClass: "3886403      2.22%      2.22%",
        CIntAlu: "129834357     74.16%     76.38%",
        CIntMult: "163025      0.09%     76.47%",
        CIntDiv: "519314      0.30%     76.76%",
        CFloatAdd: "0      0.00%     76.76%",
        CFloatCmp: "0      0.00%     76.76%",
        CFloatCvt: "0      0.00%     76.76%",
        CFloatMult: "0      0.00%     76.76%",
        CFloatMultAcc: "0      0.00%     76.76%",
        CFloatDiv: "0      0.00%     76.76%",
        CFloatMisc: "0      0.00%     76.76%",
        CFloatSqrt: "0      0.00%     76.76%",
        CSimdAdd: "0      0.00%     76.76%",
        CSimdAddAcc: "0      0.00%     76.76%",
        CSimdAlu: "0      0.00%     76.76%",
        CSimdCmp: "0      0.00%     76.76%",
        CSimdCvt: "0      0.00%     76.76%",
        CSimdMisc: "0      0.00%     76.76%",
        CSimdMult: "0      0.00%     76.76%",
        CSimdMultAcc: "0      0.00%     76.76%",
        CSimdShift: "0      0.00%     76.76%",
        CSimdShiftAcc: "0      0.00%     76.76%",
        CSimdDiv: "0      0.00%     76.76%",
        CSimdSqrt: "0      0.00%     76.76%",
        CSimdFloatAdd: "0      0.00%     76.76%",
        CSimdFloatAlu: "0      0.00%     76.76%",
        CSimdFloatCmp: "0      0.00%     76.76%",
        CSimdFloatCvt: "0      0.00%     76.76%",
        CSimdFloatDiv: "0      0.00%     76.76%",
        CSimdFloatMisc: "0      0.00%     76.76%",
        CSimdFloatMult: "0      0.00%     76.76%",
        CimdFloatMultAcc: "0      0.00%     76.76%",
        CSimdFloatMatMultAcc: "0      0.00%     76.76%",
        CSimdFloatSqrt: "0      0.00%     76.76%",
        CSimdReduceAdd: "0      0.00%     76.76%",
        CSimdReduceAlu: "0      0.00%     76.76%",
        CSimdReduceCmp: "0      0.00%     76.76%",
        CSimdFloatReduceAdd: "0      0.00%     76.76%",
        CSimdFloatReduceCmp: "0      0.00%     76.76%",
        CSimdAes: "0      0.00%     76.76%",
        CSimdAesMix: "0      0.00%     76.76%",
        CSimdSha1Hash: "0      0.00%     76.76%",
        CSimdSha1Hash2: "0      0.00%     76.76%",
        CSimdSha256Hash: "0      0.00%     76.76%",
        CSimdSha256Hash2: "0      0.00%     76.76%",
        CSimdShaSigma2: "0      0.00%     76.76%",
        CSimdShaSigma3: "0      0.00%     76.76%",
        CSimdPredAlu: "0      0.00%     76.76%",
        CMatrix: "0      0.00%     76.76%",
        CMatrixMov: "0      0.00%     76.76%",
        CMatrixOP: "0      0.00%     76.76%",
        CMemRead: "30093056     17.19%     93.95%",
        CMemWrite: "10588124      6.05%    100.00%",
        CFloatMemRead: "0      0.00%    100.00%",
        CFloatMemWrite: "0      0.00%    100.00%",
        CIprAccess: "0      0.00%    100.00%",
        CInstPrefetch: "0      0.00%    100.00%",
        CVectorUnitStrideLoad: "0      0.00%    100.00%",
        CVectorUnitStrideStore: "0      0.00%    100.00%",
        CVectorUnitStrideMaskLoad: "0      0.00%    100.00%",
        CVectorUnitStrideMaskStore: "0      0.00%    100.00%",
        CVectorStridedLoad: "0      0.00%    100.00%",
        CVectorStridedStore: "0      0.00%    100.00%",
        CVectorIndexedLoad: "0      0.00%    100.00%",
        CVectorIndexedStore: "0      0.00%    100.00%",
        CVectorUnitStrideFaultOnlyFirstLoad: "0      0.00%    100.00%",
        CVectorWholeRegisterLoad: "0      0.00%    100.00%",
        CVectorWholeRegisterStore: "0      0.00%    100.00%",
        CVectorIntegerArith: "0      0.00%    100.00%",
        CVectorFloatArith: "0      0.00%    100.00%",
        CVectorFloatConvert: "0      0.00%    100.00%",
        CVectorIntegerReduce: "0      0.00%    100.00%",
        CVectorFloatReduce: "0      0.00%    100.00%",
        CVectorMisc: "0      0.00%    100.00%",
        CVectorIntegerExtension: "0      0.00%    100.00%",
        CVectorConfig: "0      0.00%    100.00%",
        Ctotal: "175084279",
        issueRate: "0.389839",
        fuBusy: "133738152",
        fuBusyRate: "0.669828",
        numSquashedInsts: "453740",
        CSNo_OpClass: "3886403      2.22%      2.22%",
        CSIntAlu: "129834357     74.16%     76.38%",
        CSIntMult: "163025      0.09%     76.47%",
        CSIntDiv: "519314      0.30%     76.76%",
        CSMemRead: "30093056     17.19%     93.95%",
        CSMemWrite: "10588124      6.05%    100.00%",
        CStotal: "175084279",
        rdAccesses: "35073575",
        wrAccesses: "12287901",
        rdMisses: "1882979",
        wrMisses: "10",
        forwLoads: "3871360",
        squashedLoads: "7664734",
        ignoredResponses: "2745",
        memOrderViolation: "542",
        squashedStores: "3544960",
        rescheduledLoads: "24",
        blockedByCache: "0",
        LSQtotal: "30093056",
        squashCycles: "1983550",
        idleCycles: "19686785",
        blockCycles: "419328705",
        serializeStallCycles: "0",
        runCycles: "19628704",
        unblockCycles: "51534103",
        renamedInsts: "226773794",
        ROBFullEvents: "45564958",
        IQFullEvents: null,
        LQFullEvents: null,
        SQFullEvents: "15720",
        renamedOperands: "305137941",
        robReads: "725549828",
        robWrites: "449654261",
    },
    {
        _id: {},
        Name: "m5out-actual\\m5out-730",
        l1d_size: "32768",
        l2_size: "131072",
        branch_pred_type: "LocalBP",
        num_rob_entries: "32",
        core_type: "BaseO3CPU",
        num_phys_int_regs: "128",
        fu_list_0_count: "1",
        fu_list_1_count: "1",
        L1doverallHits: "37662098",
        L1doverallMisses: "4111607",
        L1doverallAccesses: "41773705",
        L1doverallMissRate: "0.098426",
        L1doverallMshrHits: "190499",
        L1doverallMshrMisses: "3932205",
        L1doverallMshrMissRate: "0.094131",
        L1dreplacements: "3932204",
        L1ioverallHits: "33477799",
        L1ioverallAccesses: "33477799",
        L2overallHits: "912653",
        L2overallMisses: "3019551",
        L2overallAccesses: "3932204",
        L2overallMissRate: "0.767903",
        L2overallMshrHits: "3531",
        L2overallMshrMisses: "3033661",
        L2overallMshrMissRate: "0.771491",
        L2replacements: "3034898",
        ctrl0readReqs: "1508826",
        ctrl0writeReqs: "118791",
        ctrl0totQLat: "29549466338",
        ctrl0totBusLat: "4997453552",
        ctrl0totMemAccLat: "55784597650",
        ctrl0avgQLat: "19701.80",
        ctrl0avgBusLat: "3332.00",
        ctrl0avgMemAccLat: "37193.80",
        ctrl0readRowHits: "442838",
        ctrl0writeRowHits: "102821",
        ctrl0readRowHitRate: "29.53",
        ctrl0writeRowHitRate: "88.86",
        ctrl0pageHitRate: "33.78",
        ctrl1readReqs: "1524835",
        ctrl1writeReqs: "118698",
        ctrl1totQLat: "29822369548",
        ctrl1totBusLat: "5050785544",
        ctrl1totMemAccLat: "56337477812",
        ctrl1avgQLat: "19673.80",
        ctrl1avgBusLat: "3332.00",
        ctrl1avgMemAccLat: "37165.80",
        ctrl1readRowHits: "451526",
        ctrl1writeRowHits: "102959",
        ctrl1readRowHitRate: "29.79",
        ctrl1writeRowHitRate: "88.99",
        ctrl1pageHitRate: "33.99",
        numCycles: "510604120",
        cpi: "5.106041",
        ipc: "0.195846",
        lookups_0: "54171087",
        squashes_0: "27155914",
        corrected_0: "3074251",
        committed_0: "27015174",
        mispredicted_0: "2690447",
        targetWrong_0: "3073723",
        condPredicted: "35746175",
        condPredictedTaken: "13653838",
        condIncorrect: "3074251",
        predTakenBTBMiss: "0",
        NotTakenMispredicted: "2122950",
        TakenMispredicted: "951301",
        BTBLookups: "54171087",
        BTBUpdates: "2122619",
        BTBHits: "36858267",
        BTBHitRatio: "0.680405",
        BTBMispredicted: "0",
        indirectLookups: "7631710",
        indirectHits: "6855900",
        indirectMisses: "775810",
        indirectMispredicted: "0",
        numInsts: "100000001",
        numOps: "175084279",
        branchMispredicts: "1983066",
        SINo_OpClass: "4920660      2.47%      2.47%",
        SIIntAlu: "145694712     73.24%     75.72%",
        SIIntMult: "185629      0.09%     75.81%",
        SIIntDiv: "521329      0.26%     76.07%",
        SIFloatAdd: "0      0.00%     76.07%",
        SIFloatCmp: "0      0.00%     76.07%",
        SIFloatCvt: "0      0.00%     76.07%",
        SIFloatMult: "0      0.00%     76.07%",
        SIFloatMultAcc: "0      0.00%     76.07%",
        SIFloatDiv: "0      0.00%     76.07%",
        SIFloatMisc: "0      0.00%     76.07%",
        SIFloatSqrt: "0      0.00%     76.07%",
        SISimdAdd: "0      0.00%     76.07%",
        SISimdAddAcc: "0      0.00%     76.07%",
        SISimdAlu: "0      0.00%     76.07%",
        SISimdCmp: "0      0.00%     76.07%",
        SISimdCvt: "0      0.00%     76.07%",
        SISimdMisc: "0      0.00%     76.07%",
        SISimdMult: "0      0.00%     76.07%",
        SISimdMultAcc: "0      0.00%     76.07%",
        SISimdShift: "0      0.00%     76.07%",
        SISimdShiftAcc: "0      0.00%     76.07%",
        SISimdDiv: "0      0.00%     76.07%",
        SISimdSqrt: "0      0.00%     76.07%",
        SISimdFloatAdd: "0      0.00%     76.07%",
        SISimdFloatAlu: "0      0.00%     76.07%",
        SISimdFloatCmp: "0      0.00%     76.07%",
        SISimdFloatCvt: "0      0.00%     76.07%",
        SISimdFloatDiv: "0      0.00%     76.07%",
        SISimdFloatMisc: "0      0.00%     76.07%",
        SISimdFloatMult: "0      0.00%     76.07%",
        SISimdFloatMultAcc: "0      0.00%     76.07%",
        SISimdFloatMatMultAcc: "0      0.00%     76.07%",
        SISimdFloatSqrt: "0      0.00%     76.07%",
        SISimdReduceAdd: "0      0.00%     76.07%",
        SISimdReduceAlu: "0      0.00%     76.07%",
        SISimdReduceCmp: "0      0.00%     76.07%",
        SISimdFloatReduceAdd: "0      0.00%     76.07%",
        SISimdFloatReduceCmp: "0      0.00%     76.07%",
        SISimdAes: "0      0.00%     76.07%",
        SISimdAesMix: "0      0.00%     76.07%",
        SISimdSha1Hash: "0      0.00%     76.07%",
        SISimdSha1Hash2: "0      0.00%     76.07%",
        SISimdSha256Hash: "0      0.00%     76.07%",
        SISimdSha256Hash2: "0      0.00%     76.07%",
        SISimdShaSigma2: "0      0.00%     76.07%",
        SISimdShaSigma3: "0      0.00%     76.07%",
        SISimdPredAlu: "0      0.00%     76.07%",
        SIMatrix: "0      0.00%     76.07%",
        SIMatrixMov: "0      0.00%     76.07%",
        SIMatrixOP: "0      0.00%     76.07%",
        SIMemRead: "35250818     17.72%     93.79%",
        SIMemWrite: "12345404      6.21%    100.00%",
        SIFloatMemRead: "0      0.00%    100.00%",
        SIFloatMemWrite: "0      0.00%    100.00%",
        SIIprAccess: "0      0.00%    100.00%",
        SIInstPrefetch: "0      0.00%    100.00%",
        SIVectorUnitStrideLoad: "0      0.00%    100.00%",
        SIVectorUnitStrideStore: "0      0.00%    100.00%",
        SIVectorUnitStrideMaskLoad: "0      0.00%    100.00%",
        SIVectorUnitStrideMaskStore: "0      0.00%    100.00%",
        SIVectorStridedLoad: "0      0.00%    100.00%",
        SIVectorStridedStore: "0      0.00%    100.00%",
        SIVectorIndexedLoad: "0      0.00%    100.00%",
        SIVectorIndexedStore: "0      0.00%    100.00%",
        SIVectorUnitStrideFaultOnlyFirstLoad: "0      0.00%    100.00%",
        SIVectorWholeRegisterLoad: "0      0.00%    100.00%",
        SIVectorWholeRegisterStore: "0      0.00%    100.00%",
        SIVectorIntegerArith: "0      0.00%    100.00%",
        SIVectorFloatArith: "0      0.00%    100.00%",
        SIVectorFloatConvert: "0      0.00%    100.00%",
        SIVectorIntegerReduce: "0      0.00%    100.00%",
        SIVectorFloatReduce: "0      0.00%    100.00%",
        SIVectorMisc: "0      0.00%    100.00%",
        SIVectorIntegerExtension: "0      0.00%    100.00%",
        SIVectorConfig: "0      0.00%    100.00%",
        SItotal: "198918552",
        CNo_OpClass: "3886403      2.22%      2.22%",
        CIntAlu: "129834357     74.16%     76.38%",
        CIntMult: "163025      0.09%     76.47%",
        CIntDiv: "519314      0.30%     76.76%",
        CFloatAdd: "0      0.00%     76.76%",
        CFloatCmp: "0      0.00%     76.76%",
        CFloatCvt: "0      0.00%     76.76%",
        CFloatMult: "0      0.00%     76.76%",
        CFloatMultAcc: "0      0.00%     76.76%",
        CFloatDiv: "0      0.00%     76.76%",
        CFloatMisc: "0      0.00%     76.76%",
        CFloatSqrt: "0      0.00%     76.76%",
        CSimdAdd: "0      0.00%     76.76%",
        CSimdAddAcc: "0      0.00%     76.76%",
        CSimdAlu: "0      0.00%     76.76%",
        CSimdCmp: "0      0.00%     76.76%",
        CSimdCvt: "0      0.00%     76.76%",
        CSimdMisc: "0      0.00%     76.76%",
        CSimdMult: "0      0.00%     76.76%",
        CSimdMultAcc: "0      0.00%     76.76%",
        CSimdShift: "0      0.00%     76.76%",
        CSimdShiftAcc: "0      0.00%     76.76%",
        CSimdDiv: "0      0.00%     76.76%",
        CSimdSqrt: "0      0.00%     76.76%",
        CSimdFloatAdd: "0      0.00%     76.76%",
        CSimdFloatAlu: "0      0.00%     76.76%",
        CSimdFloatCmp: "0      0.00%     76.76%",
        CSimdFloatCvt: "0      0.00%     76.76%",
        CSimdFloatDiv: "0      0.00%     76.76%",
        CSimdFloatMisc: "0      0.00%     76.76%",
        CSimdFloatMult: "0      0.00%     76.76%",
        CimdFloatMultAcc: "0      0.00%     76.76%",
        CSimdFloatMatMultAcc: "0      0.00%     76.76%",
        CSimdFloatSqrt: "0      0.00%     76.76%",
        CSimdReduceAdd: "0      0.00%     76.76%",
        CSimdReduceAlu: "0      0.00%     76.76%",
        CSimdReduceCmp: "0      0.00%     76.76%",
        CSimdFloatReduceAdd: "0      0.00%     76.76%",
        CSimdFloatReduceCmp: "0      0.00%     76.76%",
        CSimdAes: "0      0.00%     76.76%",
        CSimdAesMix: "0      0.00%     76.76%",
        CSimdSha1Hash: "0      0.00%     76.76%",
        CSimdSha1Hash2: "0      0.00%     76.76%",
        CSimdSha256Hash: "0      0.00%     76.76%",
        CSimdSha256Hash2: "0      0.00%     76.76%",
        CSimdShaSigma2: "0      0.00%     76.76%",
        CSimdShaSigma3: "0      0.00%     76.76%",
        CSimdPredAlu: "0      0.00%     76.76%",
        CMatrix: "0      0.00%     76.76%",
        CMatrixMov: "0      0.00%     76.76%",
        CMatrixOP: "0      0.00%     76.76%",
        CMemRead: "30093056     17.19%     93.95%",
        CMemWrite: "10588124      6.05%    100.00%",
        CFloatMemRead: "0      0.00%    100.00%",
        CFloatMemWrite: "0      0.00%    100.00%",
        CIprAccess: "0      0.00%    100.00%",
        CInstPrefetch: "0      0.00%    100.00%",
        CVectorUnitStrideLoad: "0      0.00%    100.00%",
        CVectorUnitStrideStore: "0      0.00%    100.00%",
        CVectorUnitStrideMaskLoad: "0      0.00%    100.00%",
        CVectorUnitStrideMaskStore: "0      0.00%    100.00%",
        CVectorStridedLoad: "0      0.00%    100.00%",
        CVectorStridedStore: "0      0.00%    100.00%",
        CVectorIndexedLoad: "0      0.00%    100.00%",
        CVectorIndexedStore: "0      0.00%    100.00%",
        CVectorUnitStrideFaultOnlyFirstLoad: "0      0.00%    100.00%",
        CVectorWholeRegisterLoad: "0      0.00%    100.00%",
        CVectorWholeRegisterStore: "0      0.00%    100.00%",
        CVectorIntegerArith: "0      0.00%    100.00%",
        CVectorFloatArith: "0      0.00%    100.00%",
        CVectorFloatConvert: "0      0.00%    100.00%",
        CVectorIntegerReduce: "0      0.00%    100.00%",
        CVectorFloatReduce: "0      0.00%    100.00%",
        CVectorMisc: "0      0.00%    100.00%",
        CVectorIntegerExtension: "0      0.00%    100.00%",
        CVectorConfig: "0      0.00%    100.00%",
        Ctotal: "175084279",
        issueRate: "0.389575",
        fuBusy: "133807131",
        fuBusyRate: "0.672673",
        numSquashedInsts: "421752",
        CSNo_OpClass: "3886403      2.22%      2.22%",
        CSIntAlu: "129834357     74.16%     76.38%",
        CSIntMult: "163025      0.09%     76.47%",
        CSIntDiv: "519314      0.30%     76.76%",
        CSMemRead: "30093056     17.19%     93.95%",
        CSMemWrite: "10588124      6.05%    100.00%",
        CStotal: "175084279",
        rdAccesses: "35053223",
        wrAccesses: "12263325",
        rdMisses: "1883010",
        wrMisses: "10",
        forwLoads: "3867624",
        squashedLoads: "7894824",
        ignoredResponses: "2720",
        memOrderViolation: "557",
        squashedStores: "3599204",
        rescheduledLoads: "17",
        blockedByCache: "0",
        LSQtotal: "30093056",
        squashCycles: "1983623",
        idleCycles: "19546501",
        blockCycles: "416833214",
        serializeStallCycles: "0",
        runCycles: "19565743",
        unblockCycles: "52675039",
        renamedInsts: "227549458",
        ROBFullEvents: "46567287",
        IQFullEvents: null,
        LQFullEvents: null,
        SQFullEvents: "20591",
        renamedOperands: "306120765",
        robReads: "724762474",
        robWrites: "451743574",
    },
    {
        _id: {},
        Name: "m5out-actual\\m5out-1459",
        l1d_size: "131072",
        l2_size: "131072",
        branch_pred_type: "LocalBP",
        num_rob_entries: "32",
        core_type: "BaseO3CPU",
        num_phys_int_regs: "128",
        fu_list_0_count: "1",
        fu_list_1_count: "1",
        L1doverallHits: "38514009",
        L1doverallMisses: "3191791",
        L1doverallAccesses: "41705800",
        L1doverallMissRate: "0.076531",
        L1doverallMshrHits: "149136",
        L1doverallMshrMisses: "3053020",
        L1doverallMshrMissRate: "0.073204",
        L1dreplacements: "3053019",
        L1ioverallHits: "33580632",
        L1ioverallAccesses: "33580632",
        L2overallHits: "158089",
        L2overallMisses: "2894930",
        L2overallAccesses: "3053019",
        L2overallMissRate: "0.948219",
        L2overallMshrHits: "3416",
        L2overallMshrMisses: "2907855",
        L2overallMshrMissRate: "0.952452",
        L2replacements: "3097311",
        ctrl0readReqs: "1445847",
        ctrl0writeReqs: "108961",
        ctrl0totQLat: "28090871430",
        ctrl0totBusLat: "4795587664",
        ctrl0totMemAccLat: "53266267414",
        ctrl0avgQLat: "19517.69",
        ctrl0avgBusLat: "3332.00",
        ctrl0avgMemAccLat: "37009.69",
        ctrl0readRowHits: "435222",
        ctrl0writeRowHits: "93402",
        ctrl0readRowHitRate: "30.24",
        ctrl0writeRowHitRate: "86.22",
        ctrl0pageHitRate: "34.16",
        ctrl1readReqs: "1461927",
        ctrl1writeReqs: "108950",
        ctrl1totQLat: "28359065614",
        ctrl1totBusLat: "4849409460",
        ctrl1totMemAccLat: "53817009874",
        ctrl1avgQLat: "19485.34",
        ctrl1avgBusLat: "3332.00",
        ctrl1avgMemAccLat: "36977.34",
        ctrl1readRowHits: "443916",
        ctrl1writeRowHits: "93524",
        ctrl1readRowHitRate: "30.50",
        ctrl1writeRowHitRate: "86.37",
        ctrl1pageHitRate: "34.37",
        numCycles: "496911867",
        cpi: "4.969119",
        ipc: "0.201243",
        lookups_0: "54347117",
        squashes_0: "27331944",
        corrected_0: "3074371",
        committed_0: "27015174",
        mispredicted_0: "2690471",
        targetWrong_0: "3073843",
        condPredicted: "35866831",
        condPredictedTaken: "13676638",
        condIncorrect: "3074371",
        predTakenBTBMiss: "0",
        NotTakenMispredicted: "2123009",
        TakenMispredicted: "951362",
        BTBLookups: "54347117",
        BTBUpdates: "2122645",
        BTBHits: "36985309",
        BTBHitRatio: "0.680539",
        BTBMispredicted: "0",
        indirectLookups: "7658632",
        indirectHits: "6870460",
        indirectMisses: "788172",
        indirectMispredicted: "0",
        numInsts: "100000001",
        numOps: "175084279",
        branchMispredicts: "1983134",
        SINo_OpClass: "4991068      2.52%      2.52%",
        SIIntAlu: "144864166     73.15%     75.67%",
        SIIntMult: "185655      0.09%     75.77%",
        SIIntDiv: "521329      0.26%     76.03%",
        SIFloatAdd: "0      0.00%     76.03%",
        SIFloatCmp: "0      0.00%     76.03%",
        SIFloatCvt: "0      0.00%     76.03%",
        SIFloatMult: "0      0.00%     76.03%",
        SIFloatMultAcc: "0      0.00%     76.03%",
        SIFloatDiv: "0      0.00%     76.03%",
        SIFloatMisc: "0      0.00%     76.03%",
        SIFloatSqrt: "0      0.00%     76.03%",
        SISimdAdd: "0      0.00%     76.03%",
        SISimdAddAcc: "0      0.00%     76.03%",
        SISimdAlu: "0      0.00%     76.03%",
        SISimdCmp: "0      0.00%     76.03%",
        SISimdCvt: "0      0.00%     76.03%",
        SISimdMisc: "0      0.00%     76.03%",
        SISimdMult: "0      0.00%     76.03%",
        SISimdMultAcc: "0      0.00%     76.03%",
        SISimdShift: "0      0.00%     76.03%",
        SISimdShiftAcc: "0      0.00%     76.03%",
        SISimdDiv: "0      0.00%     76.03%",
        SISimdSqrt: "0      0.00%     76.03%",
        SISimdFloatAdd: "0      0.00%     76.03%",
        SISimdFloatAlu: "0      0.00%     76.03%",
        SISimdFloatCmp: "0      0.00%     76.03%",
        SISimdFloatCvt: "0      0.00%     76.03%",
        SISimdFloatDiv: "0      0.00%     76.03%",
        SISimdFloatMisc: "0      0.00%     76.03%",
        SISimdFloatMult: "0      0.00%     76.03%",
        SISimdFloatMultAcc: "0      0.00%     76.03%",
        SISimdFloatMatMultAcc: "0      0.00%     76.03%",
        SISimdFloatSqrt: "0      0.00%     76.03%",
        SISimdReduceAdd: "0      0.00%     76.03%",
        SISimdReduceAlu: "0      0.00%     76.03%",
        SISimdReduceCmp: "0      0.00%     76.03%",
        SISimdFloatReduceAdd: "0      0.00%     76.03%",
        SISimdFloatReduceCmp: "0      0.00%     76.03%",
        SISimdAes: "0      0.00%     76.03%",
        SISimdAesMix: "0      0.00%     76.03%",
        SISimdSha1Hash: "0      0.00%     76.03%",
        SISimdSha1Hash2: "0      0.00%     76.03%",
        SISimdSha256Hash: "0      0.00%     76.03%",
        SISimdSha256Hash2: "0      0.00%     76.03%",
        SISimdShaSigma2: "0      0.00%     76.03%",
        SISimdShaSigma3: "0      0.00%     76.03%",
        SISimdPredAlu: "0      0.00%     76.03%",
        SIMatrix: "0      0.00%     76.03%",
        SIMatrixMov: "0      0.00%     76.03%",
        SIMatrixOP: "0      0.00%     76.03%",
        SIMemRead: "35170443     17.76%     93.79%",
        SIMemWrite: "12301301      6.21%    100.00%",
        SIFloatMemRead: "0      0.00%    100.00%",
        SIFloatMemWrite: "0      0.00%    100.00%",
        SIIprAccess: "0      0.00%    100.00%",
        SIInstPrefetch: "0      0.00%    100.00%",
        SIVectorUnitStrideLoad: "0      0.00%    100.00%",
        SIVectorUnitStrideStore: "0      0.00%    100.00%",
        SIVectorUnitStrideMaskLoad: "0      0.00%    100.00%",
        SIVectorUnitStrideMaskStore: "0      0.00%    100.00%",
        SIVectorStridedLoad: "0      0.00%    100.00%",
        SIVectorStridedStore: "0      0.00%    100.00%",
        SIVectorIndexedLoad: "0      0.00%    100.00%",
        SIVectorIndexedStore: "0      0.00%    100.00%",
        SIVectorUnitStrideFaultOnlyFirstLoad: "0      0.00%    100.00%",
        SIVectorWholeRegisterLoad: "0      0.00%    100.00%",
        SIVectorWholeRegisterStore: "0      0.00%    100.00%",
        SIVectorIntegerArith: "0      0.00%    100.00%",
        SIVectorFloatArith: "0      0.00%    100.00%",
        SIVectorFloatConvert: "0      0.00%    100.00%",
        SIVectorIntegerReduce: "0      0.00%    100.00%",
        SIVectorFloatReduce: "0      0.00%    100.00%",
        SIVectorMisc: "0      0.00%    100.00%",
        SIVectorIntegerExtension: "0      0.00%    100.00%",
        SIVectorConfig: "0      0.00%    100.00%",
        SItotal: "198033962",
        CNo_OpClass: "3886403      2.22%      2.22%",
        CIntAlu: "129834357     74.16%     76.38%",
        CIntMult: "163025      0.09%     76.47%",
        CIntDiv: "519314      0.30%     76.76%",
        CFloatAdd: "0      0.00%     76.76%",
        CFloatCmp: "0      0.00%     76.76%",
        CFloatCvt: "0      0.00%     76.76%",
        CFloatMult: "0      0.00%     76.76%",
        CFloatMultAcc: "0      0.00%     76.76%",
        CFloatDiv: "0      0.00%     76.76%",
        CFloatMisc: "0      0.00%     76.76%",
        CFloatSqrt: "0      0.00%     76.76%",
        CSimdAdd: "0      0.00%     76.76%",
        CSimdAddAcc: "0      0.00%     76.76%",
        CSimdAlu: "0      0.00%     76.76%",
        CSimdCmp: "0      0.00%     76.76%",
        CSimdCvt: "0      0.00%     76.76%",
        CSimdMisc: "0      0.00%     76.76%",
        CSimdMult: "0      0.00%     76.76%",
        CSimdMultAcc: "0      0.00%     76.76%",
        CSimdShift: "0      0.00%     76.76%",
        CSimdShiftAcc: "0      0.00%     76.76%",
        CSimdDiv: "0      0.00%     76.76%",
        CSimdSqrt: "0      0.00%     76.76%",
        CSimdFloatAdd: "0      0.00%     76.76%",
        CSimdFloatAlu: "0      0.00%     76.76%",
        CSimdFloatCmp: "0      0.00%     76.76%",
        CSimdFloatCvt: "0      0.00%     76.76%",
        CSimdFloatDiv: "0      0.00%     76.76%",
        CSimdFloatMisc: "0      0.00%     76.76%",
        CSimdFloatMult: "0      0.00%     76.76%",
        CimdFloatMultAcc: "0      0.00%     76.76%",
        CSimdFloatMatMultAcc: "0      0.00%     76.76%",
        CSimdFloatSqrt: "0      0.00%     76.76%",
        CSimdReduceAdd: "0      0.00%     76.76%",
        CSimdReduceAlu: "0      0.00%     76.76%",
        CSimdReduceCmp: "0      0.00%     76.76%",
        CSimdFloatReduceAdd: "0      0.00%     76.76%",
        CSimdFloatReduceCmp: "0      0.00%     76.76%",
        CSimdAes: "0      0.00%     76.76%",
        CSimdAesMix: "0      0.00%     76.76%",
        CSimdSha1Hash: "0      0.00%     76.76%",
        CSimdSha1Hash2: "0      0.00%     76.76%",
        CSimdSha256Hash: "0      0.00%     76.76%",
        CSimdSha256Hash2: "0      0.00%     76.76%",
        CSimdShaSigma2: "0      0.00%     76.76%",
        CSimdShaSigma3: "0      0.00%     76.76%",
        CSimdPredAlu: "0      0.00%     76.76%",
        CMatrix: "0      0.00%     76.76%",
        CMatrixMov: "0      0.00%     76.76%",
        CMatrixOP: "0      0.00%     76.76%",
        CMemRead: "30093056     17.19%     93.95%",
        CMemWrite: "10588124      6.05%    100.00%",
        CFloatMemRead: "0      0.00%    100.00%",
        CFloatMemWrite: "0      0.00%    100.00%",
        CIprAccess: "0      0.00%    100.00%",
        CInstPrefetch: "0      0.00%    100.00%",
        CVectorUnitStrideLoad: "0      0.00%    100.00%",
        CVectorUnitStrideStore: "0      0.00%    100.00%",
        CVectorUnitStrideMaskLoad: "0      0.00%    100.00%",
        CVectorUnitStrideMaskStore: "0      0.00%    100.00%",
        CVectorStridedLoad: "0      0.00%    100.00%",
        CVectorStridedStore: "0      0.00%    100.00%",
        CVectorIndexedLoad: "0      0.00%    100.00%",
        CVectorIndexedStore: "0      0.00%    100.00%",
        CVectorUnitStrideFaultOnlyFirstLoad: "0      0.00%    100.00%",
        CVectorWholeRegisterLoad: "0      0.00%    100.00%",
        CVectorWholeRegisterStore: "0      0.00%    100.00%",
        CVectorIntegerArith: "0      0.00%    100.00%",
        CVectorFloatArith: "0      0.00%    100.00%",
        CVectorFloatConvert: "0      0.00%    100.00%",
        CVectorIntegerReduce: "0      0.00%    100.00%",
        CVectorFloatReduce: "0      0.00%    100.00%",
        CVectorMisc: "0      0.00%    100.00%",
        CVectorIntegerExtension: "0      0.00%    100.00%",
        CVectorConfig: "0      0.00%    100.00%",
        Ctotal: "175084279",
        issueRate: "0.398529",
        fuBusy: "133969870",
        fuBusyRate: "0.676499",
        numSquashedInsts: "408688",
        CSNo_OpClass: "3886403      2.22%      2.22%",
        CSIntAlu: "129834357     74.16%     76.38%",
        CSIntMult: "163025      0.09%     76.47%",
        CSIntDiv: "519314      0.30%     76.76%",
        CSMemRead: "30093056     17.19%     93.95%",
        CSMemWrite: "10588124      6.05%    100.00%",
        CStotal: "175084279",
        rdAccesses: "34995265",
        wrAccesses: "12218393",
        rdMisses: "1883016",
        wrMisses: "10",
        forwLoads: "3877572",
        squashedLoads: "8108683",
        ignoredResponses: "2717",
        memOrderViolation: "563",
        squashedStores: "3654059",
        rescheduledLoads: "16",
        blockedByCache: "0",
        LSQtotal: "30093056",
        squashCycles: "1983697",
        idleCycles: "19430405",
        blockCycles: "402267573",
        serializeStallCycles: "0",
        runCycles: "19518663",
        unblockCycles: "53711529",
        renamedInsts: "228243302",
        ROBFullEvents: "47516653",
        IQFullEvents: null,
        LQFullEvents: null,
        SQFullEvents: "11711",
        renamedOperands: "306998445",
        robReads: "711798350",
        robWrites: "453671839",
    },
];
